import { logger } from "./logger";

// See: https://www.zoho.com/salesiq/help/developer-section/js-api.html

export function showSupportChatWindow() {
    ensureZohoSalesIQ(
        (salesiq) => salesiq.floatwindow?.visible('show')
    );
}

export function hideSupportChatWindow() {
    ensureZohoSalesIQ(
        (salesiq) => salesiq.floatwindow?.visible('hide')
    );
}

export function showSupportChatButton() {
    ensureZohoSalesIQ(
        (salesiq) => salesiq.floatbutton?.visible('show')
    );
}

export function hideSupportChatButton() {
    ensureZohoSalesIQ(
        (salesiq) => salesiq.floatbutton?.visible('hide')
    );
}

export function onChartWidgetReady(callback: () => unknown) {
    ensureZohoSalesIQ(
        (salesiq) => salesiq.ready = function () {
            callback();
        }
    )
}

function ensureZohoSalesIQ(fn: (salesiq: ZohoSalesIQ) => unknown) {
    if (!$zoho?.salesiq) {
        logger?.warn('Zoho SalesIQ not found. Support chat widget may fail.');
        return;
    }

    fn($zoho.salesiq);
}
