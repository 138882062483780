import { TaskManager } from '@/core/task-manager.js';
import { computed, ref } from 'vue';

export const taskManager = new TaskManager();


const _notificationsPanelSignal = ref<any>(1);

/**
 * Returns a signal that you should `watch` for
 * changes to know when to open the notifications panel.
 * This should only be used in the component that owns
 * the global shared notifications panel.
 * @returns
 */
export function useNotificationsPanelSignal() {
    return computed(() => _notificationsPanelSignal.value);
}

/**
 * Request global notifications panel to open.
 * This is useful if you want to help the user
 * notice ongoing tasks or new notifications.
 */
export function openNotificationsPanel() {
    // pass a dummy value to the ref to trigger listeners
    // that are watching the signal
    _notificationsPanelSignal.value = (_notificationsPanelSignal.value + 1) % 2;
}