import { logger } from "@/app-utils"
import posthog from "posthog-js"
import type { Plugin, App } from "vue"

export const posthogPlugin: Plugin = {
    install(app: App) {
        if (import.meta.env.VITE_POSTHOG_TOKEN) {
            app.config.globalProperties.$posthog = posthog.init(
                import.meta.env.VITE_POSTHOG_TOKEN,
                {
                    api_host: import.meta.env.VITE_POSTHOG_API_HOST || 'https://us.i.posthog.com',
                }
            )
        } else {
            logger?.log("PostHog not configured. Skipping initialization.");
        }
    }
}