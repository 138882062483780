import { computed, ref } from "vue";

const _paymentsDialogSignal = ref<any>(1);

/**
 * Returns a signal that you should `watch` for
 * changes to know when to open the payments dialog.
 * This should only be used in the component that owns
 * the global shared payments dialog.
 * @returns
 */
export function usePaymentsDialogSignal() {
    return computed(() => _paymentsDialogSignal.value);
}

/**
 * Request payments dialog to open.
 */
export function openPaymentsDialog() {
    // pass a dummy value to the ref to trigger listeners
    // that are watching the signal
    _paymentsDialogSignal.value = (_paymentsDialogSignal.value + 1) % 2;
}